<template>
  <el-dialog v-model="dialogVisible" :title=title width="30%">
    <el-form :model="ruleForm">

      <el-form-item label="原因:">
        <el-input v-model="ruleForm.note" type="textarea" rows="5" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="submitForm(0)">撤销</el-button>
        <el-button type="primary" @click="submitForm(1)">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref,defineExpose,reactive,defineProps } from 'vue';
import { vetoUser } from "@/api/assess.js"
import { ElMessage } from 'element-plus'

//表单变量
let ruleForm = reactive({
  note:'',
  id:0,
  g_id:0,
  value:1,
})
let issss = ref(0)
let props = defineProps({
    gid: Number
})
//提交表单
const submitForm = (type)=>{
  if(type ==0){
    dialogVisible.value = false
    return false
  }else if(type == 1){

  
  ruleForm.g_id = props.gid
  vetoUser(ruleForm).then(res=>{
    ElMessage({
      type: 'success',
      message: res.msg,
    })
    issss.value = 1
    dialogVisible.value = false

  })
  }
}

//撤销表单


//弹窗设置
let dialogVisible = ref(false)
let title = ref('这是一个标题')
const showDialog = (info,isShow) =>{
    if(isShow){
        dialogVisible.value = true
        title.value = `${info.name}的否决原因`
        ruleForm.id = info.id
        info.value = 0
      issss = info;
    }
}



defineExpose({
    showDialog
})
</script>