<template>
  <el-dialog v-model="dialogVisible" :title=title width="28%">
  <el-form label-position="right" label-width="80px">
    <el-form-item label=" 中队选择">
    <el-select v-model="value" filterable placeholder="请选择中队" size="small" @change="HandleTeamChange">
      <el-option v-for="item in teamlist" :key="item.team_id" :label="item.name" :value="item.team_id" />
    </el-select>
    </el-form-item>
    <el-form-item label=" 加分类型">
     <el-select v-model="value1" filterable placeholder="请选择加分项目" size="small">
      <el-option v-for="item in meritlist" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
    </el-form-item>
    </el-form>
    <el-divider />
候选人名单:
    <el-table :data="filterTableData" border style="width: 100%" size="small" table-layout="auto" max-height="240" v-loading="loading">
     <!--  <el-table-column type="selection" width="50" /> -->
      <el-table-column prop="id" label="" width="50" />
      <el-table-column prop="name" label="姓名" width="80" />
      <el-table-column prop="service" label="服务单位" width="180"/>
      <el-table-column >
        <template #header>
          <el-input v-model="search" size="small" placeholder="输入姓名" />
        </template>
        <template #default="scope">
        <el-button size="small" @click="handleAdd(scope.$index, scope.row)"
          >添加</el-button
        >
        </template>
      </el-table-column>
    </el-table>

<el-divider />
已选中人员
    <el-table :data="selectTable" border style="width: 100%" size="small" table-layout="auto" max-height="240">
      <el-table-column prop="id" label="id" width="50" />
      <el-table-column prop="name" label="姓名" width="80" />
      <el-table-column prop="service" label="服务单位" width="180"/>
      <el-table-column prop="m_name" label="类型" width="100"/>
      <el-table-column width="80"> 
        <template #default="scope">
        <el-button size="small" type="danger" @click="handleDel(scope.$index, scope.row)"
          >移除</el-button
        >
        </template></el-table-column>
    </el-table>
<template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">撤销</el-button>
        <el-button type="primary" @click="submitForm()">提交</el-button
        >
      </span>
    </template>

  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, computed} from 'vue';
import { getMerit,getAssessTeamList,getAssessTeamUserList,addPlus} from "@/api/assess.js";
import { ElMessage ,ElNotification} from 'element-plus'



let value = ref('')
const value1 = ref('')

let id = 0;

const loading = ref(false)

const handleAdd = (index,row) =>{
  if(value1.value){
      row['m_name'] = meritlist.value.filter(item => item.id == value1.value)[0]['name']
  row['type'] = value1.value
  selectTable.value.push(row)
    tableData.value =  tableData.value.filter ( item => item.id != row.id);
  }else{
    ElMessage.error('必须选择一个加分类型')
  }

}
const handleDel = (index,row) =>{
  tableData.value.push(row)
  selectTable.value.splice(index,1)
}

let selectTable = ref([])

const meritlist = ref([])
const teamlist = ref([])

//提交表单请求
const submitForm = ()=>{
  //console.log(id);
  //let {}
  //console.log(selectTable.value[0]);
  let temp = []
  selectTable.value.forEach((item)=>{
     // console.log(item);
      let {p_id,type} = item
      temp.push({p_id, type})
  })
  let data = {id,data:temp}
  addPlus(data).then(res=>{
        ElNotification({
    title: '操作结果',
    message: res.msg,
    type: 'success',
  })
  selectTable.value = []
  dialogVisible.value = false
  })
}

//候选人列表数据
let tableData = ref([])

const search = ref('')
const filterTableData = computed(() =>
   tableData.value.filter(
    (data) =>
      !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
  )
)

//中队变化刷新名单函数
const HandleTeamChange = (val)=>{
  loading.value = true
  getAssessTeamUserList(val).then(res=>{
    tableData.value = res.data
    loading.value = false
  })
}

//弹窗设置
let dialogVisible = ref(false)
let title = ref('这是一个标题')
const showDialog = (info, isShow) => {
  if (isShow) {
    dialogVisible.value = true
    title.value = `为${info.name}录入加分项`
    id = info.id
    getMerit().then(res=>{
      meritlist.value = res.data.list
  })
  getAssessTeamList(id).then(res=>{
    teamlist.value = res.data
  })
  }
}



defineExpose({
  showDialog
})
</script>