<template>
  <el-dialog v-model="centerDialogVisible" :title="dialogTitle" width="50%" :close-on-click-modal="false">
    <el-form size="mini" label-width="auto" ref="refForm" :disabled="Formdisabled" :model="subForm"
      :rules="formRulesMixin">
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="项目名称" prop="name" :rules="formRulesMixin.isNotNull">
              <el-input v-model="subForm.name" placeholder="请输入考核项目名称" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考核范围" prop="team_id" :rules="formRulesMixin.isNotNull">
              <el-select v-model="subForm.team_id" multiple collapse-tags style="margin-left: 20px"
                placeholder="请选择参加考核的中队">
                <el-option v-for="item in teamlist" :key="item.id" :label="item.team_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考核项目" prop="sub" :rules="formRulesMixin.isNotNull">
              <el-select v-model="subForm.sub" multiple collapse-tags style="margin-left: 20px" placeholder="请选择考评子项">
                <el-option v-for="item in aSublist" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col>
            <el-form-item label="中队长评分占比" prop="ratio.captain">
              <el-slider v-model="subForm.ratio.captain" show-input :step="1" :marks="marks" :min="1">
              </el-slider>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="服务单位评分占比" prop="ratio.service">
              <el-slider v-model="subForm.ratio.service" show-input :step="1" :marks="marks" :min="1">
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-divider></el-divider>
    <template #footer v-if="isHiddenButton">
      <span class="dialog-footer">
        <el-button type="primary" @click="AddForm" size="mini">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  defineExpose,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  ref,
  defineEmits,
  computed,
} from "vue";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import { StoreAssess } from "@/api/assess"
const emit = defineEmits(["fetchData"]);
const store = useStore();
onBeforeMount(() => {
  if (Object.keys(store.state.assess.teamList).length == 0) {
    store.dispatch("assess/getTeamList");
  }
  if (Object.keys(store.state.assess.assesssub).length == 0) {
    store.dispatch("assess/getASubList");
  }
});
const marks = reactive({
  1: "1%",
  20: "20%",
  50: {
    style: {
      color: "#1989FA",
    },
    label: "50%",
  },
});

const teamlist = computed(() => {
  return store.state.assess.teamList;
});
const aSublist = computed(() => {
  return store.state.assess.assesssub;
});

let { proxy } = getCurrentInstance();
let subForm = reactive({
  id: "",
  name: "",
  team_id: [],
  sub: [],
  ratio: {
    admin: 0,
    captain: 1,
    service: 1,
  },
});
//是否显示Dialog
let centerDialogVisible = ref(false);
let isHiddenButton = ref(true);
//初始化Dialog标题
let dialogTitle = ref("")
let Formdisabled = ref(false)
//编辑框相关
const showModal = (isEdit, insertdata) => {
  if (isEdit) {
    isHiddenButton.value = true;

    dialogTitle.value = `编辑`;
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    reshowData(insertdata);
  } else {
    subForm.id = "";
    isHiddenButton.value = true;
    dialogTitle.value = "添加考核";
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    proxy.$nextTick(() => {
      //  proxy.$refs["refForm"].resetFields();
      if (proxy.$refs["refForm"] !== undefined) {
        proxy.$refs["refForm"].resetFields();
      }
    });
  }
};

//修改
const reshowData = (row) => {
  proxy.$nextTick(() => {
    proxy.$refs["refForm"].resetFields();
    Object.keys(row).forEach((fItem) => {
      Object.keys(subForm).forEach((sItem) => {
        if (fItem === sItem) {
          subForm[sItem] = row[sItem];
        } else if (row[sItem] == undefined) {
          subForm[sItem] = " ";
        }
      });
    });
  });
};

const refForm = ref(null);
const eleMessage = (res) => {
  let type = "success";
  if (res.code != 200) {
    type = "error";
  } else if (res.code == 200) {
    type = "success";
    centerDialogVisible.value = false;
    emit("fetchData");
  }
  ElNotification({
    title: "提示",
    message: res.msg,
    type: type,
  });
};
//提交表单 校验
let AddForm = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      //校验通过后的操作
      if (subForm.id) {
        //   updateReq()
        /* UpdateUser(subForm).then((res) => {
           eleMessage(res)
            
          }); */
      } else {
        //  insertReq()
        StoreAssess(subForm).then((res) => {
          eleMessage(res)

        });
      }
    } else {
      return false;
    }
  });
};

defineExpose({
  showModal,
  eleMessage,
});
</script>

<style lang="scss" scoped>
.box-card {
  margin-bottom: 5px;
}

.dialog-footer {
  text-align: right;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}
</style>
