<template>
  <div class="top">
    <el-button type="primary" size="mini" @click="OpenForm()">新增</el-button>
  </div>
  <ListInfo ref="showForm" @fetchData="fetchData" />
  <Result ref="Drawer" />
  <Addmerit ref="addDrawer" />
  <Catmerit ref="catDrawer" />
  <Process ref="processDrawer" />
  <el-table :data="tableData" border size="mini" :height="`calc(100vh - 210px)`" :highlight-current-row="true"
    style="width: 100%">
    <el-table-column prop="id" label="id" width="60" />
    <el-table-column prop="name" align="center" label="考核项目名称">
      <template #default="{ row }">
        <span @click="showDrawer(row)">{{ row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="process" align="center" label="考核进度">
      <template v-slot="scope">
        <el-progress :percentage="scope.row.process" :text-inside="true" :stroke-width="26" :color="customColorMethod"
          @click="showProcessDrawer(scope.row)" />
      </template>
    </el-table-column>
    <el-table-column prop="status" align="center" label="考核状态">
      <template v-slot="scope">
        <el-switch v-model="scope.row.status" :loading="isload" :active-value="1" :inactive-value="0"
          @change="handlePushTask(scope.row)" />
        <!--  {{ scope.row.status == "0" ? "未发布" : "已发布" }} -->
      </template>
    </el-table-column>
    <!--     <el-table-column prop="process" align="center" label="总体平均分">
      <el-progress type="circle" :percentage="70" :width="48" :color="customColorMethod">
        <template #default="{ percentage }">
          <span class="percentage-value">{{ percentage }}</span>
        </template>
      </el-progress>
    </el-table-column> -->
    <el-table-column prop="stime" align="center" label="开始时间">
      <template v-slot="scope">
        {{ scope.row.stime == null ? "未开始" : scope.row.stime }}
      </template>
    </el-table-column>
    <el-table-column prop="endtime" align="center" label="结束时间">
      <template v-slot="scope">
        {{ scope.row.endtime == null ? "无" : scope.row.endtime }}
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template v-slot="scope">
        <el-dropdown size="small"><span class="el-dropdown-link">操作</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="showAddMerit(scope.row)">录入加分项</el-dropdown-item>
              <el-dropdown-item @click="showcatDrawer(scope.row)">查看加分列表</el-dropdown-item>
              <el-dropdown-item @click="tableEditClick(scope.row)">编辑</el-dropdown-item>
              <el-dropdown-item>
                <el-popconfirm title="是否删除该项目 项目必须是未发布的?" @confirm="tableDelClick(scope.row)">
                  <template #reference>
                    <span>删除</span>
                  </template>
                </el-popconfirm>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination background layout="total,prev, pager, next,jumper" :total="pageStruct.total"
    :page-size="pageStruct.pagesize" :current-page="pageStruct.currentpage" @current-change="handleCurrentChange">
  </el-pagination>
</template>

<script setup>
import { toRefs, reactive, onBeforeMount, ref, nextTick } from "vue";
import { getAssess, DelAssess, getAssessList, pushTask } from "@/api/assess";
import ListInfo from "../List/components/ListInfo.vue";
import Result from "../List/components/Result.vue";
import Addmerit from "../List/components/Addmerit.vue"
import Catmerit from "../List/components/Catmerit.vue"
import Process from "../List/components/Process.vue";
const showForm = ref(null);
let isload = ref(false);
const handlePushTask = (row) => {
  isload.value = true;
  pushTask(row.id).then((res) => {
    if (res.data == "ok") {
      isload.value = false;
    }
    showForm.value.eleMessage(res);
  });
};
let Drawer = ref(null);
let addDrawer = ref(null);
let catDrawer = ref(null)
let processDrawer = ref(null)
// 显示结果窗口
const showDrawer = (row) => {
  nextTick(() => {
    Drawer.value.showDrawer(row, true);
  });
};
//显示考核进度详情
const showProcessDrawer = (row) => {
  nextTick(() => {
    processDrawer.value.showDialog(row, true);
  });
};
//显示录入窗口
const showAddMerit = (row) => {
  nextTick(() => {
    addDrawer.value.showDialog(row, true);
  });
};
//显示已加分人员列表
const showcatDrawer = (row) => {
  nextTick(() => {
    catDrawer.value.showDialog(row, true);
  });
};

//添加 查看 修改
let OpenForm = () => {
  nextTick(() => {
    showForm.value.showModal();
  });
};
//查看
let pageStruct = reactive({
  currentpage: 1,
  total: 0,
  pagesize: 20,
});
//修改
let tableEditClick = (row) => {
  getAssess(row.id).then((response) => {
    if (response.code != 200) {
      return false;
    }
    nextTick(() => {
      showForm.value.showModal(true, response.data);
    });
  });
};
//删除
let tableDelClick = (row) => {
  DelAssess(row.id).then((response) => {
    showForm.value.eleMessage(response);
    fetchData();
  });
};

const state = reactive({
  tableData: null,
});
onBeforeMount(() => {
  fetchData();
});
const fetchData = () => {
  getAssessList(pageStruct.currentpage).then((response) => {
    state.tableData = response.data.list;
    pageStruct.total = response.data.count;
  });
};
//分页变化
const handleCurrentChange = (page) => {
  pageStruct.currentpage = page;
  fetchData();
};
//表格进度条颜色
const customColorMethod = (percentage) => {
  if (percentage < 30) {
    return "#909399";
  }
  if (percentage < 70) {
    return "#e6a23c";
  }
  return "#67c23a";
};

//导出属性到页面中使用
let { tableData } = toRefs(state);
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  align-items: center;
}

.top {
  margin-bottom: 10px;
}

.el-pagination {
  text-align: center;
}
</style>
