<template>
    <el-dialog v-model="dialogVisible" :title=title width="40%" @close="close" :destroy-on-close="true">
        考核进度状态:
        <el-table :data="tableData" border style="width: 100%" size="small" table-layout="auto" max-height="240"
            v-loading="loading">
            <!--  <el-table-column type="selection" width="50" /> -->
            <el-table-column prop="id" label="id" width="auto" />
            <el-table-column prop="service" label="服务单位" width="auto" />
            <el-table-column prop="process" label="进度" width="auto" />
        </el-table>
        <el-divider />
        <el-pagination small background layout="prev, pager, next" :page-size="pageStruct.pagesize"
            :total="pageStruct.total" class="mt-4" :current-page="pageStruct.current"
            @current-change="handleCurrentChange" />
    </el-dialog>
</template>

<script setup>
import { ref, defineExpose, reactive } from 'vue';
import { getProcessInfoList } from "@/api/assess.js";
//import { ElMessage ,ElNotification} from 'element-plus'




const loading = ref(true)
let gid = 0
let tableData = ref([]);
const pageStruct = reactive({
    total: 0,
    current: 1,
    pagesize: 20,
});

//分页变化
const handleCurrentChange = (page) => {
    pageStruct.current = page;
    getTableData(gid)
};
const close = () => {
    dialogVisible.value = false;
    loading.value = true;
    tableData.value = [];
};

const getTableData = (gid) => {
    loading.value = true;
    getProcessInfoList(gid, pageStruct.current).then((res) => {
        tableData.value = res.data.list;
        pageStruct.total = res.data.count;
        console.log(res.data.count);
        loading.value = false;
    });
};

//弹窗设置
let dialogVisible = ref(false)
let title = ref('这是一个标题')
const showDialog = (info, isShow) => {
    if (isShow) {
        dialogVisible.value = true
        title.value = `查看${info.name}考核详细进度`
        gid = info.id
        getTableData(gid)

    }
}



defineExpose({
    showDialog
})
</script>