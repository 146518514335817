<template>
    <el-dialog v-model="dialogVisible" :title="title" width="50%" @close="close" :destroy-on-close="true">
        加分人员名单:
        <el-table :data="filterTableData" border style="width: 100%" size="small" table-layout="auto" max-height="240"
            v-loading="loading">
            <!--  <el-table-column type="selection" width="50" /> -->
            <el-table-column prop="id" label="id" width="50" />
            <el-table-column prop="name" label="姓名" width="80" />
            <el-table-column prop="service" label="服务单位" width="auto" />
            <el-table-column prop="type" label="加分类型" width="auto" />
            <el-table-column prop="date" label="添加时间" width="auto" />
            <el-table-column>
                <template #header>
                    <el-input v-model="search" size="small" placeholder="输入姓名" />
                </template>
                <template #default="scope">
                    <el-button size="small" @click="handleDel(scope.row)">移除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-divider />
        <el-pagination small background layout="prev, pager, next" :page-size="pageStruct.pagesize"
            :total="pageStruct.total" class="mt-4" :current-page="pageStruct.current"
            @current-change="handleCurrentChange" />
    </el-dialog>
</template>

<script setup>
import { ref, defineExpose, reactive, computed } from "vue";
import { getAssessMeritUserList,DelAssessMeritUser } from "@/api/assess.js";
import { ElNotification} from 'element-plus'

//let id = 0;

const loading = ref(true);
let search = ref("");

let tableData = ref([]);
const pageStruct = reactive({
    total: 0,
    current: 1,
    pagesize: 20,
});

const filterTableData = computed(() =>
    tableData.value.filter(
        (data) =>
            !search.value ||
            data.name.toLowerCase().includes(search.value.toLowerCase())
    )
);

//分页变化
const handleCurrentChange = (page) => {
    pageStruct.current = page;
    getTableData(gid)
};
const close = () => {
    dialogVisible.value = false;
    loading.value = true;
    tableData.value = [];
};

//删除人员
const handleDel = (row) => {
    DelAssessMeritUser(row.id).then(res=>{
        let type = 'success'
        if(res.code != 200){
            type = 'error'
        }

        tableData.value = tableData.value.filter(item => item.id != row.id);

        ElNotification({
            title: '操作结果',
            message: res.msg,
            type,
        })
    })
    
};

const getTableData = (gid) => {
    loading.value = true;
    getAssessMeritUserList(gid, pageStruct.current).then((res) => {
        tableData.value = res.data.list;
        pageStruct.total = res.data.count;
        console.log(res.data.count);
        loading.value = false;
    });
};
let gid = 0
//弹窗设置
let dialogVisible = ref(false);
let title = ref("这是一个标题");
const showDialog = (info, isShow) => {
    if (isShow) {
        dialogVisible.value = true;
        title.value = `查看${info.name}加分人员列表`;
        gid = info.id
        getTableData(gid)

        //  id = info.id
    }
};

defineExpose({
    showDialog,
});
</script>
