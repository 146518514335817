<template>

  <el-drawer v-model="drawer" :title="title" direction="ltr" size="40%">
    <div>
      <reason ref="isopen" :gid="gid"/>
    </div>
    <el-row :gutter="20">
      <!--条件筛选-->
      <el-col :span="6">
        <el-select v-model="SearchForm.team_id" clearable placeholder="选择中队">
          <el-option v-for="item in teamlist" :key="item.team_id" :label="item.name" :value="item.team_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select v-model="SearchForm.level" clearable placeholder="考核成绩等级">
          <el-option label="A+" value="0"></el-option>
          <el-option label="A" value="1"></el-option>
          <el-option label="B" value="2"></el-option>
          <el-option label="C" value="3"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="success" round @click="SearchHandle()">查询</el-button>
      </el-col>
    </el-row>

    <el-table :data="tableData" stripe style="width: 90%" v-loading="loading">
      <el-table-column prop="name" label="姓名" width="180" />
      <el-table-column prop="workinfo.service" label="所属服务单位" />
      <el-table-column label="一票否决">
        <template v-slot="scope">
          <el-switch v-model="scope.row.value" size="small" :active-value="1" :inactive-value="0"
            @change="handleOver(scope.row)" />
        </template>
      </el-table-column>

    </el-table>
  </el-drawer>

</template>
<script setup>
import { ref, defineExpose, onBeforeMount, reactive } from "vue";
import { getAssessUserList, getAssessTeamList, vetoUser } from "@/api/assess.js"
import reason from "../components/reasondialog.vue"
import { ElMessage, ElMessageBox } from 'element-plus'


//
let isopen = ref(null)
let gid = ref(0)
const loading = ref(false)


 const handleOver = (row) => {
   if (row.value == 1) {
  //  changeSwich(row)
     isopen.value.showDialog(row, true)
   }else if(row.value == 0){
   ElMessageBox.confirm(
     '您真的要撤销否决吗,您的操作将会直接影响到该员工考评结果?',
     'Warning',
     {
       confirmButtonText: '是的,我仍然要撤销',
       cancelButtonText: '取消，我点错了',
       type: 'warning',
     }
   )
     .then(() => {
      //发送api
       vetoUser({ value: 0, g_id: gid.value, id: row.id}).then(res=>{
         ElMessage({
           type: 'success',
           message: res.msg,
         })
       })
      
     })
     .catch(() => {
       ElMessage({
         type: 'info',
         message: '算了,原谅你了',
       })
       row.value = 1
     })
   }
}



//////////////////显示抽屉///////////////
let drawer = ref(false)
let title = ref(null)
const teamlist = ref([])

let id = ref(0)

const showDrawer = (row, isOpen) => {
  drawer.value = isOpen
  title.value = `《${row.name}》考核结果`
  gid.value = row.id
  id.value = row.id
  if (isOpen) {
    getAssessTeamList(row.id).then(res => {
      teamlist.value = res.data
    })
  }

}
/////////////获取中队列表//////////

onBeforeMount(() => {
  /* if (Object.keys(store.state.assess.teamList).length == 0) {
     store.dispatch("assess/getTeamList")
   }*/
  /* if (Object.keys(store.state.assess.assesssub).length == 0) {
    store.dispatch("assess/getASubList")
  } */
})

/*const store = useStore();
 const teamlist = computed(() => {
  return store.state.assess.teamList
});
 */

/////////////搜索表单//////////////


const SearchForm = reactive({
  team_id: null,
  level: null
})
let tableData = ref(null)
const SearchHandle = () => {
  loading.value = true
  let data = { id: id.value, tid: SearchForm.team_id, level: SearchForm.level }
  getAssessUserList(data).then(res => {
    tableData.value = res.data
    loading.value = false

  })
}

//导出
defineExpose({
  showDrawer
})

</script>
